import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as FcIcons from "react-icons/io5";

const MatkaSetResultModal = ({ onActionPress, show, setShow }) => {
  const [betAmount, setBetAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleModalClose = () => {
    setShow(false);
    setErrorMessage(""); 
    setBetAmount(""); 
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setBetAmount(value);
    if (value > 0) {
      setErrorMessage(""); 
    }
  };

  const handleSubmit = () => {
    if (!betAmount || betAmount <= 0) {
      setErrorMessage("Please enter a valid bet amount greater than 0.");
      return;
    }
    setErrorMessage(""); 
    onActionPress(betAmount); 
    setShow(false); 
  };

  return (
    <Modal show={show} onHide={handleModalClose} size="lg">
      <Modal.Header>
        <Modal.Title>Matka Bet</Modal.Title>
        <Button variant="secondary" onClick={handleModalClose}>
          <FcIcons.IoClose />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Bet Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>20101</strong>
              </td>
              <td>
                <strong>Matka Game</strong>
              </td>
              <td>
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    value={betAmount}
                    disabled={true}
                    onChange={handleInputChange}
                    style={{
                      color: "green",
                      border: errorMessage ? "1px solid red" : "1px solid #ccc",
                      width: "100px",
                      paddingLeft: "2px",
                    }}
                  />
                  {errorMessage && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        position: "absolute",
                        top: "40px",
                      }}
                    >
                      {errorMessage}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MatkaSetResultModal;
