
export const sliderImg = [
    {
        image: '/Assets/images/cricket-home.jpg',
        alt: 'Image 1',
    },
    {
        image: '/Assets/images/tennis-home.jpg',
        alt: 'Image 2',
    },
    {
        image: '/Assets/images/football-home.jpg',
        alt: 'Image 2',
    },{
        image: '/Assets/images/casino-home.jpg',
        alt: 'Image 2',
    },

];