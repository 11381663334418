import * as signalR from "@microsoft/signalr";
import { SIGNALR_HUB_URL } from "./url";

let connection;

const configureConnection = () => {
  const uid = "?userId=" + encodeURIComponent(JSON.parse(localStorage.getItem("loginUserDetails"))?.uid);
  
  connection = new signalR.HubConnectionBuilder()
    .withUrl(SIGNALR_HUB_URL + uid)
    .withAutomaticReconnect([0, 500, 1000, 2000, 3000, 5000]) // Retry strategy
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.onclose((error) => {
    // Log the error for internal monitoring, without user notification
    console.log("SignalR connection closed due to error:", error);
    // Automatically try to reconnect
    startConnection();
  });

  connection.onreconnecting((error) => {
    console.log("SignalR reconnecting due to error:", error);
  });

  connection.onreconnected((connectionId) => {
    console.log("SignalR reconnected with connection ID:", connectionId);
  });

  connection
    .start()
    .then(() => {
      console.log("SignalR Connected!");
    })
    .catch((error) => {
      console.error("SignalR Connection Error: ", error);
      // Retry connection on failure
      setTimeout(() => startConnection(), 5000); // Delay retry
    });
};

const startConnection = () => {
  if (
    !connection ||
    connection.state === signalR.HubConnectionState.Disconnected
  ) {
    console.log("Starting SignalR connection...");
    configureConnection();
  }
};

const stopConnection = () => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    connection.stop().then(() => {
      console.log("SignalR connection stopped.");
    }).catch((error) => {
      console.error("Error stopping connection:", error);
    });
  }
};

const subscribeToEvent = (eventName, callback) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    connection.on(eventName, (data) => {
      console.log(data);
      callback(data);
    });
  } else {
    console.warn("SignalR connection not established. Attempting to reconnect.");
    startConnection(); // Automatically attempt to reconnect
  }
};

const invokeHubMethod = (methodName, ...args) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    return connection.invoke(methodName, ...args)
      .catch((error) => {
        console.error("Error invoking method:", error);
        return Promise.reject("Error invoking method");
      });
  } else {
    console.log("SignalR connection is not established. Cannot invoke method.");
    return Promise.reject("Connection not initialized");
  }
};

export { startConnection, stopConnection, subscribeToEvent, invokeHubMethod };
