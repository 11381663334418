import axios from 'axios';
import { LOGINAPI, LOGOUTAPI, CHECKISVALIDAPI } from './url';
import { getAuthHeaders } from './authUtils';

class AuthServiceClass {
    async loginService(data) {
        const headers = {
            "Content-Type": "application/json",
        };
        return await axios.post(`${LOGINAPI}?ipAddress=${data?.ip}`, data?.data, {
            headers: headers,
        });
    }
    async logOutService() {
        const headers = getAuthHeaders();
        return await axios.get(`${LOGOUTAPI}`, {
            headers: headers,
        });
    }
    async CheckIsValidService() {
        const headers = getAuthHeaders();
        return await axios.get(`${CHECKISVALIDAPI}`, {
            headers: headers,
        });
    }
}

const AuthService = new AuthServiceClass();
export default AuthService;