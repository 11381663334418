import React from "react";
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import BetTableList from "./MatkaBetTableList";

const MatkaBetList = () => {

   
    return (
        <Tab.Container defaultActiveKey="tab1" id="tab-layout">
            <Nav variant="tabs" className="custom-nav-tabs mx-0 mb-0">
                <Nav.Item>
                    <Nav.Link eventKey="tab1" className="custom-nav-link2 cricket">Running </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab2" className="custom-nav-link2">Completed </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                    <Nav.Link eventKey="tab3" className="custom-nav-link2">Fancy Bets ({filteredFancyList?.length})</Nav.Link>
                </Nav.Item> */}
            </Nav>
            <Row className="justify-content-center">
                <Col>
                    <Tab.Content>
                        <Tab.Pane eventKey="tab1">
                            <div className="tab-content custom-tab-content">
                                <BetTableList  />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab2">
                            <div className="tab-content custom-tab-content">
                                <BetTableList />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab3">
                            <div className="tab-content custom-tab-content">
                                <BetTableList  />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>

    )
}

export default MatkaBetList;