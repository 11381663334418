import React from "react";
import { Modal, Button } from "react-bootstrap";
import * as FcIcons from "react-icons/io5";

const BetListTableModal = ({ onActionPress, show, setShow, betDataList }) => {
  const handleModalClose = () => {
    setShow(false);
  };

  const handleSubmit = () => {
    onActionPress();
    setShow(false);
  };

  // Calculate the total amount
  const totalAmount = betDataList.reduce((sum, item) => sum + parseFloat(item.amount), 0);

  return (
    <Modal show={show} onHide={handleModalClose} size="lg">
      <Modal.Header>
        <Modal.Title>Confirm Bet</Modal.Title>
        <Button variant="secondary" onClick={handleModalClose}>
          <FcIcons.IoClose />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Bet Number</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {betDataList.map((item, index) => (
              <tr key={index}>
                <td>{item.betNumber}</td>
                <td>{item.amount}</td>
              </tr>
            ))}
            <tr>
              <td>
                Total Bets: <strong>{betDataList.length}</strong>
              </td>
              <td>
                Total Amount: <strong>{totalAmount.toFixed(2)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BetListTableModal;
