import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./screen/Home";
import SignIn from "./screen/Auth/SignIn";
import SignUp from "./screen/Auth/SignUp";
import InPlay from "./screen/InPlay";
import Match from "./screen/Match";
import InfoBranding from "./screen/InfoBranding";
import AccountStatements from "./screen/Report/AccountStatements";
import ProfitAndLoss from "./screen/Report/Profit&Loss";
import BetHistory from "./screen/Report/BetHistory";
import Market from "./screen/Market";
import News from "./screen/News";
import NetworkErr from "./screen/NetworkErr";
import MyMarket from "./screen/MyMarket";
import ShowMatchBet from "./screen/Report/ShowMatchBet";
import BettingHistory from "./screen/Report/BettingHistory";
import CasinoList from "./screen/CasinoList/CasinoList";
import Casino from "./screen/Casino/Casino";
import MatkaBet from "./screen/Matka/MatkaBet";
import MatkaBetHistory from "./screen/Matka/MatkaBetResult";
import LoadingSpinner from "./components/LoadingSpinner";
import { useAuth } from "./context/AuthContext";
import MatkaMarket from "./screen/Matka/MatkaBet/MatkaMarket";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  console.log("ProtectedRoute -> isLoggedIn:", isLoggedIn);
  return isLoggedIn ? children : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  console.log("PublicRoute -> isLoggedIn:", isLoggedIn);
  return !isLoggedIn ? children : <Navigate to="/home" replace />;
};

function App() {
  const { isLoading, isLoggedIn } = useAuth();

  console.log("App State -> isLoading:", isLoading, "isLoggedIn:", isLoggedIn);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute><InfoBranding /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />

        {/* Protected Routes */}
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/casinolist" element={<ProtectedRoute><CasinoList /></ProtectedRoute>} />
        <Route path="/casino/:gameid" element={<ProtectedRoute><Casino /></ProtectedRoute>} />
        <Route path="/inplay" element={<ProtectedRoute><InPlay /></ProtectedRoute>} />
        <Route path="/match/:sportId" element={<ProtectedRoute><Match /></ProtectedRoute>} />
        <Route path="/ac_statement" element={<ProtectedRoute><AccountStatements /></ProtectedRoute>} />
        <Route path="/showmatchbet/:eventid" element={<ProtectedRoute><ShowMatchBet /></ProtectedRoute>} />
        <Route path="/betting_history/:marketid/:userId" element={<ProtectedRoute><BettingHistory /></ProtectedRoute>} />
        <Route path="/profit-loss" element={<ProtectedRoute><ProfitAndLoss /></ProtectedRoute>} />
        <Route path="/bethistory" element={<ProtectedRoute><BetHistory /></ProtectedRoute>} />
        <Route path="/market/:sportId/:eventId" element={<ProtectedRoute><Market /></ProtectedRoute>} />
        <Route path="/news" element={<ProtectedRoute><News /></ProtectedRoute>} />
        <Route path="/mymarket" element={<ProtectedRoute><MyMarket /></ProtectedRoute>} />
        <Route path="/matkabet" element={<ProtectedRoute><MatkaBet /></ProtectedRoute>} />
        <Route path="/matka_market" element={<ProtectedRoute><MatkaMarket /></ProtectedRoute>} />
        <Route path="/makta_bethistory" element={<ProtectedRoute><MatkaBetHistory /></ProtectedRoute>} />
        <Route path="/network_err" element={<PublicRoute><NetworkErr /></PublicRoute>} />

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to={isLoggedIn ? "/home" : "/login"} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
