import React, { useEffect } from "react";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { setSportNameById } from "../../utilities";
import { useSelector } from "react-redux";

const MyMarket = () => {
    const myMarketList = useSelector((state) => state.marketSlice?.myMarketList?.Result)
    const Navigate = useNavigate()
    useEffect(() => {
        actions.getMyMarketAction();
    }, [])
    const handleMatchSelect = (eventID, sportId, SeriesName, MarketName) => {
        Navigate(`/market/${sportId}/${eventID}`, { state: { seriesName: SeriesName, matchName: MarketName } });
    }
    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0" >
                <div className="table-responsive mt-">
                    <div className="table-wrapper">
                        <table className="table  table-bordered">
                            <thead>
                                <th>EventId</th>
                                <th>Match</th>
                                <th>Sport Name</th>
                                <th>MarketType</th>
                                <th>Team A</th>
                                <th>Team B</th>
                                <th>Draw</th>
                            </thead>
                            <tbody>
                                {myMarketList && Array.isArray(myMarketList) && myMarketList?.length > 0 ? myMarketList?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="dark-table-cell" > {item?.EventId}</td>
                                        <td className="dark-table-cell" onClick={() => handleMatchSelect(item.EventId, item.SportsId, item.MarketName, item.SeriesName)}  >{item?.MarketName} </td>
                                        <td className="dark-table-cell">{setSportNameById(item?.SportsId)} </td>
                                        <td className="dark-table-cell">{item?.MarketType} </td>
                                        <td className={`${item?.Runners[0]?.profitLoss < 0 ? 'dark-table-cell red-text' : 'dark-table-cell green-text'}`}>{item?.Runners[0]?.profitLoss} </td>
                                        <td className={`${item?.Runners[1]?.profitLoss < 0 ? 'dark-table-cell red-text' : 'dark-table-cell green-text'}`}>{item?.Runners[1]?.profitLoss} </td>
                                        <td className="dark-table-cell"> </td>
                                    </tr>
                                ))
                                    :
                                    <tr>
                                        <td className="text-center" colSpan='6'>No Result</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </NavbarSidebarWrapper>
    )
}

export default MyMarket;