import React, { useState } from "react";
import MatkaSetResultModal from "../../../components/Modal/MatkaBet/MatkaSetResultModal";
import { useSelector } from "react-redux";

const BetTableList = () => {
  const staticData = [
    { id: 1, game: "Soccer Match", status: "Running", time: "12:30 PM", date: "2025-01-01" },
    { id: 2, game: "Basketball Game", status: "Completed", time: "03:00 PM", date: "2025-01-02" },
    { id: 3, game: "Tennis Match", status: "Running", time: "10:15 AM", date: "2025-01-03" },
  ];

  const betDataList = useSelector((state) => state.matkabetSlice.betsOfUsersHistory);

  const [showModal, setShowModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  const handleSetResult = (game) => {
    setSelectedGame(game);
    setShowModal(true);
  };

  const handleActionPress = (betAmount) => {
    console.log(`Result set for ${selectedGame.game}: Bet Amount = ${betAmount}`);
    setShowModal(false);
  };

  return (
    <div className="table-responsive">
      <div className="table-wrapper">
        <div className="table">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Game</th>
                <th>Status</th>
                <th>Time</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {staticData.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.game}</td>
                  <td>{row.status}</td>
                  <td>{row.time}</td>
                  <td>{row.date}</td>
                  <td>
                    {row.status !== "Running" ? (
                      <button className="btn btn-primary" onClick={() => handleSetResult(row)}>
                        View Details
                      </button>
                    ) : (
                      <button className="btn btn-secondary" disabled>
                        Result Pending
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <MatkaSetResultModal
        show={showModal}
        setShow={setShowModal}
        onActionPress={handleActionPress}
      />
    </div>
  );
};

export default BetTableList;
