import React from "react";
import { sliderImg } from "./sliderImg";
import BannerSlider from "../../components/BannerSlider";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    if(id!==5)
    navigate(`/match/${id}`)
  else
  navigate(`/casinolist`)
  }
 
  return (

    <NavbarSidebarWrapper>

      <div className="content-wrapper me-0" > {/* main content wrapper */}
        <div className='d-flex flex-wrap'>

          <BannerSlider slides={sliderImg} />

          <div className="tag-container">
            <div className="tag-text">trending games</div>
          </div>
          <div className="">
            <div className="row g-1">
              <div className="col-6 col-sm-4  px-1 mb-2" onClick={() => handleNavigate(4)}>
                <div className="gameCard" tabIndex={0}>
                  <img
                    src="/assets/images/cricket-home.jpg"
                    className="img-fluid"
                    alt="cricket"
                  />
                  <div className="gameName">
                    Cricket
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 px-1 mb-2" onClick={() => handleNavigate(1)}>
                <div className="gameCard" tabIndex={0}>
                  <img
                    src="/assets/images/football-home.jpg"
                    className="img-fluid"
                    alt="football"
                  />
                  <div className="gameName">
                    Soccer
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4  px-1 mb-2" onClick={() => handleNavigate(2)}>
                <div className="gameCard" tabIndex={0}>
                  <img
                    src="/assets/images/tennis-home.jpg"
                    className="img-fluid"
                    alt="tennis"
                  />
                  <div className="gameName">
                    Tennis
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4  px-1 mb-2" onClick={() => handleNavigate(5)}>
                <div className="gameCard" tabIndex={0}>
                  <img
                    src="/assets/images/casino-home.jpg"
                    className="img-fluid"
                    alt="Casino"
                  />
                  <div className="gameName">
                    Casino
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    
    </NavbarSidebarWrapper>
  );
};

export default SignIn;
