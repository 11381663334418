import moment from 'moment';

export const formattedDateTime = (DateTime) => {
    return moment(DateTime).utc().local().format("D MMM YYYY hh:mm A");
}

export const isLoggedIn = () => {
    let value = localStorage.getItem("loggedIn")
    if (value === "1") { return true }
    else { return false }
}

export const setLoggedIn = (value) => {
    localStorage.setItem("loggedIn", value)
}

export const setSportNameById = (id) => {
    let idString = id?.toString();
    if (idString === "4") { return "Cricket" }
    if (idString === "1") { return "Soccer" }
    if (idString === "2") { return "Tennis" }

    return ""
}

export const isBetSound = () => {
    const value = localStorage.getItem("betsound");
    return value ? JSON.parse(value) : false;
};
  
export const setBetSound = (value) => {
    localStorage.setItem("betsound", JSON.stringify(value));
};