import { createSlice } from "@reduxjs/toolkit";

const matkabetSlice = createSlice({
    name: 'matkabetSliceData',
    initialState: {
        betsOfUsersHistory: []
    },
    reducers: {
        saveBetsOfUserHistory: (state, action) => {
            state.betsOfUsersHistory = action.payload
        },
    }
})

export const { saveBetsOfUserHistory } = matkabetSlice.actions;
export default matkabetSlice.reducer