import axios from 'axios';
import { 
  GETACTIVEFANCYAPI, GETSCOREAPI, GETTENNISSCORE, GETCUSTOMEBMAPI, 
  GETMARKETLISTAPI, GETBETFARERETSAPI, GETEVENTDETAIL, GETMYMARKETAPI, 
  GETBETFAREBMFANCYAPI, GETBETFAREMARKETOTHERAPI, GETBETFARETOSSAPI, 
  GETCHIPMODALAPI, UPDATECHIPMODALAPI, POSTUSERBETAPI, GETNEWSAPI, 
  GETALLNEWSAPI, POSTFANCYBETAPI, GETUSERBETMODELAPI, GETFANCYPOSTIONAPI, 
  GETPROFITLOSSRUNNERAPI, GETFANCYCURRENT, GETUSERBOOK 
} from './url';
import { getAuthHeaders } from './authUtils';

class MarketServiceClass {

  async getMarketDetailsService() {
    const headers = getAuthHeaders();
    return await axios.get(GETMARKETLISTAPI, { headers });
  }

  async getMyMarketService() {
    const headers = getAuthHeaders();
    return await axios.get(GETMYMARKETAPI, { headers });
  }

  async getBetfareRetsService(marketids) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETBETFARERETSAPI}?marketids=${marketids}`, { headers });
  }

  async getEventDetailService(eventId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETEVENTDETAIL}?eventId=${eventId}`, { headers });
  }

  async getBetfareRetsBMAndFancyService(marketids) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETBETFAREBMFANCYAPI}/${marketids}`, { headers });
  }

  async getBetfareMarketAndOtherService(marketids) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETBETFAREMARKETOTHERAPI}/${marketids}`, { headers });
  }

  async getBetfareTossOddsService(marketids) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETBETFARETOSSAPI}?marketids=${marketids}T`, { headers });
  }

  async getChipModalService() {
    const headers = getAuthHeaders();
    return await axios.get(GETCHIPMODALAPI, { headers });
  }

  async updateChipModalService(data) {
    const headers = getAuthHeaders();
    return await axios.post(UPDATECHIPMODALAPI, data, { headers });
  }

  async postUserBetAPIService(data) {
    const headers = getAuthHeaders();
    return await axios.post(POSTUSERBETAPI, data, { headers });
  }

  async GetNewsService() {
    const headers = getAuthHeaders();
    return await axios.get(GETNEWSAPI, { headers });
  }

  async GetAllNewsService() {
    const headers = getAuthHeaders();
    return await axios.get(GETALLNEWSAPI, { headers });
  }

  async postFancyBetAPIService(data) {
    const headers = getAuthHeaders();
    return await axios.post(POSTFANCYBETAPI, data, { headers });
  }

  async GetBetModelList(eventId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETUSERBETMODELAPI}?marketId=${eventId}`, { headers });
  }

  async GetFancyPostion(marketId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETFANCYPOSTIONAPI}?marketId=${marketId}`, { headers });
  }

  async GetProfitLossOnRunners(marketId, sportId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETPROFITLOSSRUNNERAPI}?marketId=${marketId}&sportsId=${sportId}`, { headers });
  }

  async getActiveFancyListService(MarketId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETACTIVEFANCYAPI}?MarketId=${MarketId}`, { headers });
  }

  async getCustomeBMService(MarketId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETCUSTOMEBMAPI}?MarketId=${MarketId}`, { headers });
  }

  async getFancyCurrentService(data) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETFANCYCURRENT}?selectionId=${data.selectionId}&marketId=${data.marketId}`, { headers });
  }

  async getUserBookService(bookData) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETUSERBOOK}?marketId=${bookData.marketId}&uid=${bookData.uid}`, { headers });
  }

  async GetScoreDataService(eventId) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETSCOREAPI}/${eventId}`, { headers });
  }

  async GetTennisScoreService(data) {
    const headers = getAuthHeaders();
    return await axios.get(`${GETTENNISSCORE}/${data?.sportId}/${data?.eventId}`, { headers });
  }
}

const MarketService = new MarketServiceClass();
export default MarketService;
