import React, { useEffect, useState } from "react";
import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import * as BsIcons from "react-icons/bs";
import actions from "../../../redux/actions";
import moment from "moment";
import { useSelector } from "react-redux";
import { formattedDateTime } from "../../../utilities";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const ProfitAndLoss = () => {
  let { user } = useAuth();

  const profitLossList = useSelector(
    (state) => state.reportSlice?.profitAndLossDetails?.Result
  );
  const totalrow = useSelector(
    (state) => state.reportSlice?.profitAndLossDetails?.count
  );
  let totalPage = Math.ceil(parseInt(totalrow) / 10);
  let Navigate = useNavigate();
  const [data, setData] = useState({
    userId: encodeURIComponent(user.uid),
    sportsId: 0,
    psize: 10,
    skipRec: 0,
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    let data = {
      userId: encodeURIComponent(user.uid),
      sportsId: 0,
      skipRec: 0,
      psize: 10,
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
    actions.getProfitAndLoss(data);
  }, [user.uid]);

  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleResetData = (e) => {
    e.preventDefault();
    setData({
      ...data,
      sportsId: 0,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  };

  const callProfitLossAction = (e) => {
    e.preventDefault();
    actions.getProfitAndLoss(data);
  };

  const handlePageNoChange = (newPageNo) => {
    setData((prevState) => ({ ...prevState, skipRec: newPageNo }));
    actions.getProfitAndLoss({ ...data, skipRec: newPageNo });
  };

  const DynamicPagination = (number) => {
    let divCount = number;
    if (number > 4) {
      divCount = 4;
    }
    const divs = [];
    for (let i = number - divCount + 1; i <= number; i++) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${data?.skipRec + 1 === i
              ? "pagination-number "
              : "pagination-inactive-number "
              }`}
            onClick={() => {
              setData((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };
  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        {" "}
        {/* main content wrapper */} {/* Apply the custom border color */}
        <div className="content-header d-flex justify-content-between">
          <span className="">Profit Loss Listing</span>
        </div>
        <div className="row ">
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Select Sport
            </label>
            <select
              className="form-select custom-input"
              value={data?.sportsId}
              name="sportsId"
              onChange={handleChange}
            >
              <option value="0">All Sports</option>
              <option value="4">Cricket</option>
              <option value="1">Soccer</option>
              <option value="2">Tennis</option>
            </select>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose from Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.startDate}
              name="startDate"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose to Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.endDate}
              name="endDate"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mt-1">
          <button
            type="button"
            className="btn btn-sm violet_button me-2"
            onClick={callProfitLossAction}
          >
            Search
          </button>
          <button
            type="button"
            className="btn btn-sm grey_button"
            onClick={handleResetData}
          >
            <BsIcons.BsEraserFill /> Reset
          </button>
        </div>
        <div className="table-responsive mt-2">
          <div className="table-wrapper">
            <table className="table table-striped">
              <thead>
                <th>Description</th>
                <th>P&L</th>
                <th>Commission</th>
                <th>Date</th>
              </thead>
              <tbody>
                {profitLossList &&
                  Array.isArray(profitLossList) &&
                  profitLossList?.length > 0 ? (
                  profitLossList?.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="dark-table-cell active-link"
                        onClick={() =>
                          Navigate(`/showmatchbet/${item.EventId}`, {
                            state: { returnUrl: "profit-loss" },
                          })
                        }
                      >
                        {" "}
                        {item?.Discription}
                      </td>
                      <td
                        className={`${item?.Amount < 0
                          ? "dark-table-cell red-text"
                          : "dark-table-cell green-text"
                          }`}
                      >
                        {item?.Amount}{" "}
                      </td>
                      {/*   <td className="dark-table-cell">{item?.Balance} </td> */}
                      <td className="dark-table-cell long-row-td">
                        {item?.Commission}{" "}
                      </td>
                      <td className="dark-table-cell">
                        {formattedDateTime(item?.createdOn)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="4">
                      No Result
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="dark-table-cell">
                    (Total P & L ) 0
                  </td>
                  <td colSpan="3" className="dark-table-cell">
                    (Total Commition) 0
                  </td>
                </tr>
              </tfoot>
            </table>
            {profitLossList && profitLossList?.length > 0 && (
              <nav aria-label="Page navigation example" className="me-1">
                <ul class="pagination justify-content-start mb-1">
                  <li class="page-item me-1">
                    <button
                      class="page-link pagination-icon"
                      disabled={data?.skipRec === 0 ? true : false}
                      onClick={() => {
                        handlePageNoChange(data?.skipRec - 1);
                      }}
                    >
                      Previous
                    </button>
                  </li>
                  {DynamicPagination(totalPage)}

                  <li class="page-item">
                    <button
                      class="page-link pagination-icon"
                      disabled={data?.skipRec === totalPage - 1 ? true : false}
                      onClick={() => {
                        handlePageNoChange(data?.skipRec + 1);
                      }}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default ProfitAndLoss;
