import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ToastProvider } from './components/ToastContext';
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ToastProvider>
      <AuthProvider> {/* Wrap the App component with AuthProvider */}
        <App />
      </AuthProvider>
    </ToastProvider>
  </Provider>,
);
