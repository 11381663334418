import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import actions from "../../../redux/actions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import * as MdIcons from 'react-icons/md';
import { useAuth } from "../../../context/AuthContext";

const ShowMatchBet = () => {
    const matchBetList = useSelector((state) => state.reportSlice?.matchBetDetails?.Result);
    let { eventid } = useParams();
    let { user } = useAuth();

    let navigate = useNavigate();
    useEffect(() => {
        let data = {
            marketId: eventid,
            role: 'Client',
            userId: encodeURIComponent(user.uid)
        }

        actions.getMatchBet(data)
    }, [eventid, user.uid])


    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0" > {/* main content wrapper */} {/* Apply the custom border color */}
                <div className="content-header d-flex justify-content-between">
                    <span className="">Show Bet</span>
                </div>
                <button className="btn violet_button" onClick={() => navigate(`/profit-loss`)}><MdIcons.MdArrowBackIos />Back</button>
                <div className="table-responsive mt-2">
                    <div className="table-wrapper">
                        <table className="table table-striped">
                            <thead>
                                <th>S.No.</th>
                                <th>Market Name</th>
                                <th>Market Type</th>
                                <th>P&L</th>
                                <th>Commission</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {matchBetList && Array.isArray(matchBetList) && matchBetList?.length > 0 ? matchBetList?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="dark-table-cell">{index + 1}</td>
                                        <td className='dark-table-cell'>{item?.MarketName} </td>
                                        <td className="dark-table-cell">{item?.marketType} </td>
                                        <td className={`${item?.profitLoss < 0 ? 'dark-table-cell red-text' : 'dark-table-cell green-text'}`}>{item?.profitLoss} </td>
                                        <td className="dark-table-cell">{item?.commission} </td>
                                        <td className="dark-table-cell active-link" onClick={() => navigate(`/betting_history/${item?.marketId}/${item?.userId}`, { state: { eventid: eventid, SelectionID: item?.Selectionid, marketType: item?.marketType } })}>show bet</td>

                                    </tr>
                                ))
                                    :
                                    <tr>
                                        <td className="text-center" colSpan='6'>No Result</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </NavbarSidebarWrapper>
    )
}

export default ShowMatchBet;