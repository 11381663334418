import React from "react";
import { useNavigate } from "react-router-dom";
import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";

const markets = [
    { id: 1, name: "DISAWER", hindi: "देसावर", time: "09:00 - 04:00" },
    { id: 2, name: "GAZIYABAD", hindi: "गाज़ियाबाद", time: "09:00 - 19:45" },
    { id: 3, name: "Gali", hindi: "गली", time: "08:00 - 22:30" },
    { id: 4, name: "FARIDABAD", hindi: "फरीदाबाद", time: "09:00 - 17:45" },
    { id: 5, name: "DELHI BAZAR", hindi: "दिल्ली बाजार", time: "09:00 - 14:50" },
    { id: 6, name: "Shree ganesh", hindi: "श्री गणेश", time: "09:00 - 15:45" },
];

const MatkaMarket = () => {
    const navigate = useNavigate();

    const handleNavigate = (id) => {
        navigate(`/matkabet`);
    };

    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0">
                <div className="d-flex flex-wrap">
                    <div className="tag-container">
                        <div className="tag-text">Matka Games</div>
                    </div>
                    <div className="row g-1">
                        <div className="smcardContainer">
                            {markets.map((market) => (
                                <div
                                    key={market.id}
                                    className="smcard"
                                    onClick={() => handleNavigate(market.id)}
                                    onKeyPress={(e) => e.key === "Enter" && handleNavigate(market.id)}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <div className="smimageContainer">
                                        <img
                                            src="/assets/images/football-home.jpg"
                                            alt={`${market.name} - ${market.hindi}`}
                                            className="smimage"
                                            loading="lazy"
                                        />
                                        <div className="smoverlay"></div>
                                    </div>
                                    <div className="smgameTitle">
                                        <strong>{market.name}</strong> {market.hindi}
                                    </div>
                                    <div className="smgameTime">
                                        20-01-2025 {market.time}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </NavbarSidebarWrapper>
    );
};

export default MatkaMarket;
