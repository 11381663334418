import AuthService from "../../services/authService";

export const loginAction = (data) => {
  return new Promise((resolve, rejact) => {
    AuthService.loginService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        rejact(error);
      });
  });
};

export const logOutAction = () => {
  return new Promise((resolve, rejact) => {
    AuthService.logOutService()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        rejact(error);
      });
  });
};

export const CheckIsValidAction = () => {
  return new Promise((resolve, rejact) => {
    AuthService.CheckIsValidService()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          sessionStorage.removeItem('loginUserDetails');
          window.location = "/login";
        } else {
          window.location = `/network_err?statusCode=${error?.response?.status}`;
        }
        rejact(error);
      });
  });
};
