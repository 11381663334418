export const getAuthHeaders = () => {
  const userDetails = JSON.parse(localStorage.getItem("loginUserDetails"));
  if (!userDetails || !userDetails.Token) {
    throw new Error("User not authenticated");
  }

  return {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${userDetails.Token}`
  };
};

export function handleUnauthorizedError(error) {
  if (error?.response?.status === 401) {
    localStorage.removeItem('loginUserDetails');
    window.location = '/login';
  }
  console.error("API error:", error);
}
