import React, { createContext, useState, useEffect, useContext } from "react";
import { stopConnection } from "../services/SignalRService";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loginUserDetails"));
    if (loggedInUser && !user) {
      setUser(loggedInUser);
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }, [user]);

  const login = (userData) => {
    localStorage.setItem("loginUserDetails", JSON.stringify(userData));
    setUser(userData);
    setIsLoggedIn(true);
  };

  const logout = () => {
    stopConnection();
    localStorage.removeItem("loginUserDetails");
    setUser(null);
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isLoading, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
