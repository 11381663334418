import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import store from "../../redux/store";
import { setBetBookShowModal } from "../../redux/slices/userSlice";
import { setUserBookName } from "../../redux/slices/marketSlice";
import actions from "../../redux/actions";
import ScoreBoard from "../../components/ScoreBoard/ScoreBoard";
import ScoreBoardTennis from "../../components/ScoreBoard/ScoreBoardTennis";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Bookmaker = ({
  eventDetail,
  handleShowBackPlaceBet,
  handleShowLayPlaceBet,
}) => {
  const { sportId } = useParams();
  let { user } = useAuth();
  const marketFareRate = useSelector(
    (state) => state.marketSlice.BetfareRetsMarketOdds
  );
  const bmFancyFareRate = useSelector(
    (state) => state.marketSlice.BetfareRetsBMAndFancyData?.BookMaker
  );
  const tossFareRate = useSelector(
    (state) => state.marketSlice.BetfareTossOddsData?.Result
  );
  const activeBMList = useSelector((state) => state.marketSlice.CustomeBMList);
  const eventLock = useSelector((state) => state.marketSlice.eventBetLock);
  const selectMatchingRunner2 = () => marketFareRate || [];
  const selectMatchingRunner3 = () => bmFancyFareRate || [];
  const selectMatchingRunner4 = () => tossFareRate || [];

  const selectAllRunners = createSelector(
    [selectMatchingRunner2, selectMatchingRunner3, selectMatchingRunner4],
    (matchingRunner2, matchingRunner3, matchingRunner4) =>
      [].concat(matchingRunner2).concat(matchingRunner3).concat(matchingRunner4)
  );

  const allRunners = useSelector(selectAllRunners);

  activeBMList.forEach((activeItem) => {
    if (
      activeItem.IsCustome === true &&
      activeItem.status.toLowerCase() === "active"
    ) {
      allRunners.push({
        marketId: activeItem.MarketId,
        status: activeItem.status,
        runners: activeBMList
          .map((runner) => {
            if (runner.MarketId === activeItem.MarketId) {
              return {
                selectionId: runner.selectionId,
                RunnerName: runner.RunnerName,
                status: runner.status,
                ex: {
                  availableToBack: [
                    {
                      price: runner.BackPrice1,
                      size: runner.BackSize1,
                    },
                  ],
                  availableToLay: [
                    {
                      price: runner.LayPrice1,
                      size: runner.LaySize1,
                    },
                  ],
                },
              };
            }
            return null;
          })
          .filter(Boolean),
      });
    }
  });

  const updatedAllRunners = allRunners?.map((bookmarklist) => {
    return {
      ...bookmarklist,
      runners: bookmarklist?.runners?.map((bookmarkRunner) => {
        const matchingRunnelistItem = activeBMList?.find(
          (runnelistItem) =>
            runnelistItem.IsActive === true &&
            runnelistItem.IsCustome === true &&
            runnelistItem.MarketId === bookmarklist.marketId &&
            runnelistItem.RunnerName === bookmarkRunner.RunnerName + ""
        );

        if (matchingRunnelistItem) {
          const updatedBookmarkRunner = JSON.parse(
            JSON.stringify(bookmarkRunner)
          );

          // Update the properties directly without nested objects
          updatedBookmarkRunner.ex.availableToLay[0].size =
            matchingRunnelistItem.LaySize1;
          updatedBookmarkRunner.ex.availableToBack[0].size =
            matchingRunnelistItem.BackSize1;
          updatedBookmarkRunner.ex.availableToLay[0].price =
            matchingRunnelistItem.LayPrice1;
          updatedBookmarkRunner.ex.availableToBack[0].price =
            matchingRunnelistItem.BackPrice1;
          updatedBookmarkRunner.status = matchingRunnelistItem.status;

          return updatedBookmarkRunner;
        }

        return bookmarkRunner;
      }),
    };
  });

  const bookHandler = (market) => {
    store.dispatch(setUserBookName(market?.MarketName));
    console.log("Runner", market);
    let data = {
      marketId: market?.MarketId,
      uid: encodeURIComponent(user.uid),
    };
    actions.getUserBook(data);
    store.dispatch(setBetBookShowModal(true));
  };

  return (
    <div>
      {Number(sportId) === 4 ? <ScoreBoard /> : <ScoreBoardTennis />}
      {eventDetail?.map((market) => (
        <div key={market.MarketId}>
          <div className="tag-container white-tag-container">
            <div className="tag-text">{market?.MarketType}</div>
          </div>

          <div className="table-responsive">
            <table className="table table-dark table-striped">
              <thead>
                <tr className="td-tr-botton-border">
                  <th className="font-weight-bold" style={{ width: "50%" }}>
                    Min/Max {market?.MinStake}-{market?.MaxStake}
                  </th>
                  <th style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      className="back market-lay-back"
                      style={{ marginRight: "10px" }}
                    >
                      <span
                        className="font-weight-bold"
                        style={{ color: "white" }}
                      >
                        Back
                      </span>
                    </div>
                    <div className="lay market-lay-back">
                      <span
                        className="font-weight-bold"
                        style={{ color: "white" }}
                      >
                        Lay
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {market?.runners?.map((runner) => {
                  const matchingRunner = updatedAllRunners?.find(
                    (runner2) => runner2?.marketId === runner?.marketId
                  );

                  var matchingRunner2 = [];
                  if (market.MarketType === "Bookmaker") {
                    matchingRunner2 = matchingRunner?.runners.find(
                      (runner2) =>
                        runner2?.RunnerName.replace(".", "") ===
                        runner?.RunnerName.replace(".", "")
                    );
                  } else {
                    matchingRunner2 = matchingRunner?.runners.find(
                      (runner2) =>
                        runner2?.selectionId.toString() ===
                        runner?.selectionId.toString()
                    );
                  }

                  return (
                    <tr
                      key={runner.selectionId}
                      className="td-tr-botton-border"
                    >
                      <td className="font-weight-bold">
                        {runner.RunnerName} <br />
                        <a role="button" onClick={() => bookHandler(market)}>
                          <span
                            style={{ color: runner.book < 0 ? "red" : "green" }}
                          >
                            {runner.book}
                          </span>
                        </a>
                      </td>
                      {/* //EvBetStatus =false than all market will show suspend*/}
                      {/* <td className={eventLock && matchingRunner2?.status === "ACTIVE" ? '' : 'suspended-event'} style={{ display: 'flex', position: 'relative', justifyContent: 'center', '--dynamic-content': `"${eventLock && matchingRunner2?.status === "ACTIVE" ? matchingRunner2?.status : "SUSPENDED"}"` }}>
                                                <div className='rate back market-lay-back ' style={{ marginRight: '10px' }} onClick={() => handleShowBackPlaceBet(0, matchingRunner2, market)}>
                                                    <span className="font-weight-bold">{matchingRunner2?.ex.availableToBack[0]?.price ? matchingRunner2?.ex.availableToBack[0]?.price : "0"}</span>
                                                    <br />
                                                    <span>{matchingRunner2?.ex.availableToBack[0]?.size ? matchingRunner2?.ex.availableToBack[0]?.size : "0"}</span>
                                                </div>
                                                <div className='rate lay market-lay market-lay-back' onClick={() => handleShowLayPlaceBet(0, matchingRunner2, market, runner)}>
                                                    <span className="font-weight-bold">{matchingRunner2?.ex.availableToLay[0]?.price ? matchingRunner2?.ex.availableToLay[0]?.price : "0"}</span>
                                                    <br />
                                                    <span>{matchingRunner2?.ex.availableToLay[0]?.size ? matchingRunner2?.ex.availableToLay[0]?.size : "0"}</span>
                                                </div>
                                            </td> */}
                      <td
                        className={
                          eventLock && matchingRunner2?.status === "ACTIVE"
                            ? ""
                            : "suspended-event"
                        }
                        style={{
                          display: "flex",
                          position: "relative",
                          justifyContent: "center",
                          "--dynamic-content": `"${
                            eventLock && matchingRunner2?.status === "ACTIVE"
                              ? matchingRunner2?.status
                              : "SUSPENDED"
                          }"`,
                        }}
                      >
                        <div
                          className="rate back market-lay-back "
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            handleShowBackPlaceBet(
                              0,
                              matchingRunner2,
                              market,
                              runner
                            )
                          }
                        >
                          <span className="font-weight-bold">
                            {eventLock
                              ? matchingRunner2?.ex.availableToBack[0]?.price ||
                                "0"
                              : "0"}
                          </span>
                          <br />
                          {market.MarketType === "Bookmaker" ? (
                            <span> 0 </span>
                          ) : (
                            <span>
                              {eventLock
                                ? matchingRunner2?.ex.availableToBack[0]
                                    ?.size || "0"
                                : "0"}
                            </span>
                          )}
                        </div>
                        <div
                          className="rate lay market-lay market-lay-back"
                          onClick={() =>
                            handleShowLayPlaceBet(
                              0,
                              matchingRunner2,
                              market,
                              runner
                            )
                          }
                        >
                          <span className="font-weight-bold">
                            {eventLock
                              ? matchingRunner2?.ex.availableToLay[0]?.price ||
                                "0"
                              : "0"}
                          </span>
                          <br />
                          {market.MarketType === "Bookmaker" ? (
                            <span> 0 </span>
                          ) : (
                            <span>
                              {eventLock
                                ? matchingRunner2?.ex.availableToLay[0]?.size ||
                                  "0"
                                : "0"}
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Bookmaker;
