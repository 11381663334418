export const generateTableData = () => {
    const rows = [];
    for (let i = 0; i < 10; i++) {
        const row = [];
        for (let j = 1; j <= 10; j++) {
            row.push(i * 10 + j);
        }
        rows.push(row);
    }
    return rows;
};

export const generateCrossingData = () => ({
    A: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
    B: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
});

export const generateHarupData = () => ({
    A: ["0000", "1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"],
    B: ["000", "111", "222", "333", "444", "555", "666", "777", "888", "999"],
});

export const parseInputValue = (value) => {
    const trimmedValue = value.trim();
    const pattern = /^([\d-]+)@([\d]+)$/;
    const match = trimmedValue.match(pattern);

    if (!match) {
        return null;
    }

    const numbers = match[1]; 
    const amount = match[2];  
    const numberArray = numbers.split("-");

    // Validate and map the numbers
    const isValidBetNumber = (num) => /^\d+$/.test(num) && +num >= 0 && +num <= 99;
    const result = numberArray
        .filter(isValidBetNumber) // Only include valid bet numbers
        .map((num) => ({
            betNumber: num,
            amount: amount,
        }));

    return result.length ? result : null; // Return null if no valid bet numbers
};