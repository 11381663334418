const prod = {
 // API_BASE_URL: "https://api2.shriexchange.com",
  API_BASE_URL: "https://testapi.shriexchange.com",
};
const dev = {
  //API_BASE_URL: "https://api2.shriexchange.com",
  //API_BASE_URL: "https://localhost:44371",
   API_BASE_URL: "https://testapi.shriexchange.com",
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
