import { saveUserBalanceDetails } from "../slices/userSlice";
import UserService from "../../services/userService";
import store from "../store";
import { handleUnauthorizedError } from "../../services/authUtils";

export function changePasswordAction(data) {
    return new Promise((resolve, rejact) => {
        UserService.changePasswordService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error)
            rejact(error)
        })
    })
}
export function GetUserBalanceAction() {
    return new Promise((resolve, rejact) => {
        UserService.GetUserBalanceService().then((res) => {
            store.dispatch(saveUserBalanceDetails(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error)
            rejact(error)
        })
    })
}
