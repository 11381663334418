import axios from 'axios';
import { ACCOUNTSTATEMENTAPI, BETHISTORYAPI, PROFITANDLOSSAPI, MATCHBETAPI, ACCOUNTBETHISTORYAPI, ACCOUNTFANCYBETHISTORYAPI } from './url';
import { getAuthHeaders } from './authUtils';

class ReportServiceClass {

    async getAccountStatementService(data) {
        const headers = getAuthHeaders();
        return await axios.get(`${ACCOUNTSTATEMENTAPI}?uid=${encodeURIComponent(JSON.parse(localStorage.getItem("loginUserDetails"))?.uid)}&skipRec=${data?.skipRec}&type=${data?.type}&psize=${data?.psize}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }

    async getMatchBetService(data) {
        const headers = getAuthHeaders();
        return await axios.get(`${MATCHBETAPI}?marketId=${data.marketId}&role=${data.role}&uid=${data.userId}`, {
            headers: headers,
        });
    }

    async getAccountBetHistoryService(data) {
        const headers = getAuthHeaders();
        return await axios.get(`${ACCOUNTBETHISTORYAPI}?marketId=${data.marketId}&role=${data.role}&userId=${data.userId}`, {
            headers: headers,
        });
    }

    async getAccountFancyBetHistoryService(data) {
        const headers = getAuthHeaders();
        return await axios.get(`${ACCOUNTFANCYBETHISTORYAPI}?marketId=${data.marketId}&userId=${data.userId}`, {
            headers: headers,
        });
    }

    async getProfitAndLossService(data) {
        const headers = getAuthHeaders();
        return await axios.get(`${PROFITANDLOSSAPI}?skipRec=${data?.skipRec}&psize=${data?.psize}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }

    async getBetHistoryService(data) {
        const headers = getAuthHeaders();
        return await axios.get(`${BETHISTORYAPI}?userId=${data?.userId}&skipRec=${data?.skipRec}&marketType=${data?.marketType}&betStatus=${data?.betStatus}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }
}

const ReportService = new ReportServiceClass();
export default ReportService;
