import React, { useEffect } from 'react';

const LoadingSpinner = () => {
  // Styles for the spinner container
  const spinnerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  };

  // Die container style
  const dieStyle = {
    width: '100px',
    height: '100px',
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    animation: 'diceRotate 1s infinite linear', // Changed to linear for smoother rotation
  };

  // Dot style for the die faces
  const dotStyle = {
    width: '15px',
    height: '15px',
    backgroundColor: 'black',
    borderRadius: '50%',
    position: 'absolute',
  };

  // Dice face dot positions for 1-6 faces
  const dotPositions = {
    1: [{ top: '50%', left: '50%' }],
    2: [{ top: '20%', left: '20%' }, { bottom: '20%', right: '20%' }],
    3: [{ top: '20%', left: '20%' }, { top: '50%', left: '50%' }, { bottom: '20%', right: '20%' }],
    4: [{ top: '20%', left: '20%' }, { top: '20%', right: '20%' }, { bottom: '20%', left: '20%' }, { bottom: '20%', right: '20%' }],
    5: [{ top: '20%', left: '20%' }, { top: '20%', right: '20%' }, { bottom: '20%', left: '20%' }, { bottom: '20%', right: '20%' }, { top: '50%', left: '50%' }],
    6: [
      { top: '20%', left: '20%' }, { top: '20%', right: '20%' },
      { top: '50%', left: '20%' }, { top: '50%', right: '20%' },
      { bottom: '20%', left: '20%' }, { bottom: '20%', right: '20%' },
    ],
  };

  // Injecting keyframes for the die rotation animation
  const injectKeyframes = () => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes diceRotate {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);
  };

  // Inject keyframes when the component mounts
  useEffect(() => {
    injectKeyframes();
  }, []);

  // Rendering the dots on the die faces
  const renderDots = (dots) => {
    return dots.map((dot, index) => (
      <div
        key={index}
        style={{
          ...dotStyle,
          top: dot.top,
          left: dot.left,
          bottom: dot.bottom,
          right: dot.right,
        }}
      />
    ));
  };

  return (
    <div style={spinnerStyle}>
      <div style={dieStyle}>
        {/* Dynamically rendering dots for a random face */}
        {renderDots(dotPositions[4])} 
      </div>
    </div>
  );
};

export default LoadingSpinner;