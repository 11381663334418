import { saveAccountStatement, saveProfitAndLoss, saveBetHistory, saveAccountBetHistory, saveMatchBet } from "../slices/reportSlice";
import ReportService from "../../services/reportService";
import store from "../store";
import { handleUnauthorizedError } from "../../services/authUtils";

export function getAccountStatement(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountStatementService(data).then((res) => {
            store.dispatch(saveAccountStatement(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}
export function getMatchBet(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getMatchBetService(data).then((res) => {
            store.dispatch(saveMatchBet(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}
export function getAccountBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountBetHistoryService(data).then((res) => {
            store.dispatch(saveAccountBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}
export function getAccountFancyBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountFancyBetHistoryService(data).then((res) => {
            store.dispatch(saveAccountBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}
export function getProfitAndLoss(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getProfitAndLossService(data).then((res) => {
            store.dispatch(saveProfitAndLoss(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}
export function getBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getBetHistoryService(data).then((res) => {
            store.dispatch(saveBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            rejact(error)
        })
    })
}
