import { setCustomeBMList, setScoreData, setTennisScoreData, setFancyActiveList, saveMarketDetails, saveBetfareRets, setEventDetail, saveMyMarketDetails, saveBfRMarketOdddsOther, saveNewsData, saveAllNewsData, saveBetfareRetsBMAndFancy, saveBfRTossOddds, setChipModalDetails, setMatchBetData, setFancyPostionData, setProfitLossOnRunnersData, setCurrentFP, setUseBookData } from "../slices/marketSlice";
import MarketService from "../../services/marketService";
import store from "../store";
import { handleUnauthorizedError } from "../../services/authUtils";

export function getMarketList() {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketDetailsService().then((res) => {
            store.dispatch(saveMarketDetails(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function getMyMarketAction() {
    return new Promise((resolve, rejact) => {
        MarketService.getMyMarketService().then((res) => {
            store.dispatch(saveMyMarketDetails(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function getBetfareRets(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareRetsService(marketids).then((res) => {
            store.dispatch(saveBetfareRets(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function getEventDetail(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.getEventDetailService(eventId).then((res) => {
            store.dispatch(setEventDetail(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function getChipModal() {
    return new Promise((resolve, rejact) => {
        MarketService.getChipModalService().then((res) => {
            store.dispatch(setChipModalDetails(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function updateChipModal(data) {
    return new Promise((resolve, rejact) => {
        MarketService.updateChipModalService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function GetNews() {
    return new Promise((resolve, rejact) => {
        MarketService.GetNewsService().then((res) => {
            store.dispatch(saveNewsData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function GetAllNews() {
    return new Promise((resolve, rejact) => {
        MarketService.GetAllNewsService().then((res) => {
            store.dispatch(saveAllNewsData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function getBetfareRetsBMAndFancy(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareRetsBMAndFancyService(marketids).then((res) => {
            store.dispatch(saveBetfareRetsBMAndFancy(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}


export function getBetfareRetsMarketAndOther(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareMarketAndOtherService(marketids).then((res) => {
            store.dispatch(saveBfRMarketOdddsOther(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function getBetfareTossOdds(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareTossOddsService(marketids).then((res) => {
            store.dispatch(saveBfRTossOddds(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function postUserBetAPI(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postUserBetAPIService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function postFancyBetAPI(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postFancyBetAPIService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function GetMatchedBetList(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetBetModelList(eventId).then((res) => {
            store.dispatch(setMatchBetData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function GetFancyPostionList(marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetFancyPostion(marketId).then((res) => {
            store.dispatch(setFancyPostionData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}
export function GetProfitLossOnRunnersList(marketId, sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetProfitLossOnRunners(marketId, sportId).then((res) => {
            store.dispatch(setProfitLossOnRunnersData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
            // rejact(error)
        })
    })
}

export function getactiveFancyList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getActiveFancyListService(MarketId).then((res) => {
            store.dispatch(setFancyActiveList(res.data.Result))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
        })
    })
}

export function getCustomeBMList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getCustomeBMService(MarketId + "B").then((res) => {
            store.dispatch(setCustomeBMList(res.data.Result))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
        })
    })
}

export function getFancyCurrent(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getFancyCurrentService(data).then((res) => {
            store.dispatch(setCurrentFP(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
        })
    })
}

export function getUserBook(bookData) {
    return new Promise((resolve, rejact) => {
        MarketService.getUserBookService(bookData).then((res) => {
            store.dispatch(setUseBookData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
        })
    })
}

export function GetScoreData(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetScoreDataService(eventId).then((res) => {
            store.dispatch(setScoreData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
        })
    })
}

export function GetTennisScore(data) {
    return new Promise((resolve, rejact) => {
        MarketService.GetTennisScoreService(data).then((res) => {
            store.dispatch(setTennisScoreData(res.data))
            resolve(res)
        }).catch((error) => {
            handleUnauthorizedError(error);
        })
    })
}